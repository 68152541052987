<template>
    <div class="classes">
        <div v-if="classesList.length > 0">
            <b-row>
                <b-col
                    lg="3"
                    md="6"
                    v-for="classIem in paginationList"
                    :key="classIem.id"
                >
                    <ColorCard
                        :type="'class'"
                        :dto="classIem"
                        :colors="classIem.colors"
                        :level="
                            educationLevelList.find(
                                item => item.id == classIem.educationalLevel
                            ).name
                        "
                        :name="classIem.name"
                        @details="classDetail(classIem)"
                        @delete="delClass(classIem.id)"
                        @edit="editClass(classIem)"
                    >
                    </ColorCard>
                </b-col>
                <b-col cols="12" class="d-flex justify-content-center">
                    <ek-pagination
                        :items="classesList"
                        v-model="paginationList"
                        :pageLength="8"
                    ></ek-pagination>
                </b-col>
            </b-row>
        </div>
        <div v-else class="text-center mt-2">
            <h4>لا يوجد صفوف</h4>
        </div>
    </div>
</template>
<script>
import ColorCard from "@/app/admin/components/ElearningCard/ColoredCard.vue";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
    components: {
        ColorCard
        // EKPagination
    },
    data() {
        return {
            paginationList: []
        };
    },
    methods: {
        classDetail(item) {
            this.$router.push({
                path: `/admin/classes/${item.id}`,
                query: { name: item.name }
            });
        },
        delClass(id) {
            this.deleteClass(id);
        },
        editClass(dto) {
            this.$store.commit("IS_DIALOG_OPEN", true);
            this.$store.commit("SET_CLASS_DTO", dto);
        },

        ...mapActions([
            "getClasses",
            "getByIdClass",
            "getColors",
            "deleteClass",
            "updateClass"
        ])
    },
    computed: {
        ...mapState({
            classList: state => state.classes.classList,
            educationLevelList: state => state.classes.educationLevelList
        }),
        ...mapGetters(["classesList"])
    },
    created() {
        this.getClasses();
    },
    mounted() {}
};
</script>

<style lang="scss">
@import "/src/@core/scss/core.scss";
.classes {
    ul {
        li {
            svg {
                height: 20px;
                width: 20px;
            }
        }
    }
    .unicon.button {
        svg {
            fill: $main-color !important ;
        }
    }
    h5 {
        margin: 0;
        margin-left: 10px;
    }
    svg {
        fill: #6e6b7b;
    }
    .card-footer {
        text-align: center;
        cursor: pointer;
        button {
            color: #fff !important;
            border-top-left-radius: 0 !important;
            border-top-right-radius: 0 !important;
        }
    }
}
</style>
