<template>
    <b-card class="colored-card position-relative">
        <template #header>
            <div
                class="lighter"
                :style="`background: rgb(2,0,36);
                            background: linear-gradient(90deg, ${colors[0]} 0%, ${colors[1]} 100%);
                            height:5px; width:100%`"
            ></div>
            <div class="d-flex flex-column w-100">
                <div class="d-flex justify-content-between align-items-center">
                    <h3 class="m-0">{{ name }}</h3>

                    <b-dropdown
                        id="drop"
                        size="md"
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                    >
                        <template
                            #button-content
                            @click="$emit('options', id)"
                            id="btn-drop"
                        >
                            <unicon name="ellipsis-v"></unicon>
                        </template>
                        <b-dropdown-item @click="deleteBtn" class="svg-class">
                            <span>حذف</span>
                            <unicon class="ml-3" name="trash"></unicon>
                        </b-dropdown-item>
                        <b-dropdown-item @click="editBtn">
                            <span>تعديل</span>
                            <unicon class="ml-3" name="pen"></unicon>
                        </b-dropdown-item>
                    </b-dropdown>
                </div>

                <h4 v-if="type == 'class'">{{ level }}</h4>
                <h4 v-else>
                    {{ subjectName + "-" + className + "-" + semester }}
                </h4>

                <b-row>
                    <b-col
                        lg="12"
                        class="d-flex align-items-center justify-contnet-start"
                    >
                        <unicon
                            :name="types.find((el) => el.type == type).icon1"
                            class="mr-2"
                        ></unicon>
                        <div
                            class="d-flex align-items-center justify-content-start"
                        >
                            <span>({{ dto.studentsCount }})</span>
                            <h5>
                                {{ types.find((el) => el.type == type).count1 }}
                            </h5>
                        </div>
                    </b-col>
                    <b-col
                        lg="12"
                        class="d-flex align-items-center justify-content-start mt-50"
                    >
                        <unicon
                            :name="types.find((el) => el.type == type).icon2"
                            class="mr-2"
                        ></unicon>
                        <div
                            class="d-flex align-items-center justify-content-start"
                        >
                            <span v-if="type == 'class'"
                                >({{ dto.subjectsCount }})</span
                            >
                            <span v-else>({{ dto.lessonsCount }})</span>
                            <h5>
                                {{ types.find((el) => el.type == type).count2 }}
                            </h5>
                        </div>
                    </b-col>
                    <b-col
                        lg="12"
                        class="d-flex align-items-center justify-contnet-start"
                    >
                        <unicon
                            :name="types.find((el) => el.type == type).icon3"
                            class="mr-2"
                        ></unicon>
                        <div
                            class="d-flex align-items-center justify-content-start"
                        >
                            <span>({{ dto.chaptersCount }})</span>
                            <h5>
                                {{ types.find((el) => el.type == type).count3 }}
                            </h5>
                        </div>
                    </b-col>
                    <b-col
                        lg="12"
                        class="d-flex align-items-center justify-contnet-start"
                    >
                        <unicon
                            :name="types.find((el) => el.type == type).icon4"
                            class="mr-2"
                        ></unicon>
                        <div
                            class="d-flex align-items-center justify-content-start"
                        >
                            <span>({{ dto.order }})</span>
                            <h5>
                                {{ types.find((el) => el.type == type).count4 }}
                            </h5>
                        </div>
                    </b-col>
                    <b-col
                        lg="12"
                        class="d-flex align-items-center justify-contnet-start"
                    >
                        <unicon
                            :name="types.find((el) => el.type == type).icon5"
                            class="mr-2"
                        ></unicon>
                        <div
                            class="d-flex align-items-center justify-content-start"
                        >
                            <span>({{ dto.price }})</span>
                            <h5>
                                {{ types.find((el) => el.type == type).count5 }}
                            </h5>
                        </div>
                    </b-col>
                    <b-col
                        lg="12"
                        class="d-flex align-items-center justify-contnet-start"
                    >
                        <unicon
                            :name="types.find((el) => el.type == type).icon6"
                            class="mr-2"
                        ></unicon>
                        <div
                            class="d-flex align-items-center justify-content-start"
                        >
                            <span>({{ isFreeText }})</span>
                            <h5>
                                {{ types.find((el) => el.type == type).count6 }}
                            </h5>
                        </div>
                    </b-col>
                    <b-col lg="12" class="d-flex justify-content-start mt-50">
                        <unicon name="calendar-alt" class="mr-2"></unicon>
                        <div
                            class="d-flex align-items-center justify-content-start"
                        >
                            <h5 class="m-0">تاريخ الإضافة:</h5>
                            <span>{{
                                new Date(dto.dateCreated).toLocaleDateString()
                            }}</span>
                        </div>
                    </b-col>
                </b-row>
            </div>
        </template>
        <slot></slot>
        <template #footer>
            <b-button
                variant="main-color"
                @click="classDetail"
                class="w-100 p-1"
                >عرض التفاصيل</b-button
            >
        </template>
    </b-card>
</template>

<script>
export default {
    props: {
        name: { type: String, default: "name" },
        colors: { type: Array, default: () => [] },
        level: { type: String, default: "" },
        subjectName: { type: String, default: "" },
        className: { type: String, default: "" },
        semester: String,
        studentsCount: Number,
        lessonsCount: Number,
        type: String,
        dto: Object,
    },
    data() {
        return {
            types: [
                {
                    type: "class",
                    icon1: "users-alt",
                    icon2: "books",
                    count1: "عدد الطلاب  ",
                    count2: "عدد المواد",
                },
                {
                    type: "unit",
                    icon1: "file-question-alt",
                    icon2: "books",
                    icon3: "bookmark",
                    icon4: "books",
                    icon5: "dollar-sign-alt",
                    icon6: "label",

                    count1: "عدد الاشتراكات ",
                    count2: "الدروس",
                    count3: "عدد الأبحاث ",
                    count4: "ترتيب الوحدة",
                    count5: "السعر",
                    count6: "الحالة",
                },
            ],
        };
    },
    computed: {
        isFreeText() {
            return this.dto.isFree ? "مجاني" : "غير مجاني";
        },
    },
    methods: {
        classDetail() {
            this.$emit("details", this.id);
        },
        deleteBtn() {
            this.$emit("delete", this.id);
        },
        editBtn() {
            this.$emit("edit", this.id);
        },
    },
};
</script>

<style lang="scss">
.colored-card {
    background-color: #ffffffc0;
    backdrop-filter: blur(2px);
    // z-index: -99;
    position: relative;
    .lighter {
        border-radius: 2.5rem;
        position: absolute;
        right: 0;
        top: 0px;
        z-index: -1;
    }
    .card-header {
        display: block !important;
        #drop {
            z-index: 999 !important;
            ul {
                z-index: 999 !important;
                top: -15px !important;
            }
        }
    }

    .card-footer {
        padding: 0 !important;
        text-align: center;
        cursor: pointer;
        button {
            color: #fff !important;
            border-top-left-radius: 0 !important;
            border-top-right-radius: 0 !important;
        }
    }
}
</style>
